.sectors-container {
  padding: 20px;
}

.sectors-flex {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  text-align: center;
  align-self: center;
  justify-content: center;
  min-height: 10em;
}

.sector-card {
  width: 15%;
  height: 200px;
  padding: 1.3em;
  color: #1e6aa0;
  line-height: 2em;
  font-family: 'Lato', sans-serif;
  box-shadow: 0 1rem 2rem hsl(0 0% 0% / 20%);
  cursor: pointer;
  background-size: cover;
  background-position: center;
  border-radius: 8px;
  position: relative;
  transition: transform 0.3s, box-shadow 0.3s;
  overflow: hidden;
}

.sector-card:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.sector-content {
  position: relative;
  z-index: 1;
  text-align: center;
  color: white;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
}
.main-sections{
display: flex;
align-items: center;
justify-content: center;
justify-items: center;
align-content: center;
max-width: 80%;
box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
gap: 8em;
background: linear-gradient(to right,white, #e8ebec);
margin: 0 auto;
padding: 3em;
min-height: 100%;

}
.list_sections{
  span{
    color: #8aa01e;
    margin-left: 2em;
  }
}
.sector-card:hover .subsect_title {
  color: white;
}
.list_sectiosn{
  width: 30%;
  height: 30vh;
  span{
    color: #8aa01e;
    margin-left: 2em;
  }
}
.btn_read {
  padding: 1em;
  color: aliceblue;
  cursor: pointer;
}
.details_sector{
display: flex;
align-items: center;
flex-direction: column;
justify-content: center;
width: 30%;
height: 30vh;
}
.items_details{
  line-height: 1.5em;
}
.subsect_title {
  font-size: 1.2em;
  font-weight: bold;
  margin-bottom: 20px;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px;
  border-radius: 5px;
}
.title_details{
  color: green;
  max-width: 5%;
}
.sector-link {
  text-decoration: none;
  color: inherit;
}

.read_btn {
  display: none;
  margin-top: auto;
}

.sector-card:hover .read_btn {
  display: block;
}

.read_btn Button {
  background-color: #1888d7;
  border: none;
  color: white; /* Ensure button text color is white */
  padding: 0.5em 1em;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.read_btn Button:hover {
  background-color: #0a6bbf; /* Slightly darker shade on hover */
}
.categories_details{
  line-height: 1.5em;
  color: #1e6aa0;
}
:hover.categories_details{
  color: rgb(15, 187, 130);
}
@media screen and (max-width:1300px) {
  .items_details{
    line-height: 1.5em;
    min-width: 100%;
  }
  .main-sections{
    max-width: 90%;
    min-height: 80vh;
    padding: 0.5em;
    gap: 2em;
  }
}
@media (max-width: 768px) {
  .sector-card {
    width: 100%;
  }
  .main-sections{
    flex-direction: column;
    gap:2em;
    max-width: 100%;
  }
  .items_details{
    max-width: 100%;
  }
  .details_sector{
    min-width: 100%;
  }
}
@media screen and (max-width:550px){
  .main-sections{
   margin-top: 8em;
    display: flex;
    flex-direction: column;
    min-height: 100%;
  }
  .details_sector{
    margin-top: 3em;
  }
  
}