/* Resources.css */

/* Container */
.resources-container {
    padding: 1rem;
    max-width: 80rem;
    margin: auto;
    text-align: center;
  }
  
  /* Heading */
  .resources-title {
    font-size: 2rem;
    font-weight: 800;
    color: #2d3748;
    margin-bottom: 1.5rem;
  }
  
  /* Button Group */
  .resources-buttons {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 0.5rem;
  }
  
  .resources-button {
    padding: 0.4em;
    margin: 0.5em;
    font-weight: 500;
    border-radius: 0.5em;
    transition: background-color 0.3s ease-in-out;
    border: none;
    cursor: pointer;
  }
  
  .resources-button.inactive {
    background-color: #e5e7eb;
  }
  
  .resources-button.inactive:hover {
    background-color: #d1d5db;
  }
  
  /* Image Grid */
  .resources-gallery {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1.7em;
    justify-content: center;
  }
  
  .resources-card {
    width: 90%;
    border-radius: 1em;
    overflow: hidden;
  }
  
  .resources-card img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 0.5em;
    margin: 0.5em;
  }
  
  /* Responsive Styles */
  @media (max-width: 1024px) {
    .resources-gallery {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  
  @media (max-width: 768px) {
    .resources-gallery {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media (max-width: 480px) {
    .resources-gallery {
      grid-template-columns: repeat(1, 1fr);
    }
  
    .resources-title {
      font-size: 1.5rem;
    }
  
    .resources-button {
      font-size: 0.9rem;
      padding: 0.4em;
    }
  }
  