/* about.css */
.about_us_container {
  padding: 3em; 
  display: flex;
  gap: 4em;
  background-color: #f9f9f9;
  background-size: cover; 
  background-position: center; 
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
  .about_description_container{
    display: flex;
    gap: 3em;
  }
  .about_nav ul li {
    cursor: pointer;
    padding: 10px;
    list-style: none;
  }
  
  .about_nav ul li.active {
    font-weight: bold;
    color: #007bff;
    text-decoration: underline;
  }
  
.intro_details {
  color: #0f4f7c;
  font-weight: 600;
  text-align: center;
  font-size: 1.5em;
  max-width: 70%;
  margin: auto; /* Center align horizontally */
}
.intro_title{
  color: #7c520f;
  font-weight: 600;
  border-bottom: solid rgb(119, 115, 115) 3em;
  max-width: 20%;
  /* padding-left: 20em; */
  text-align: center;
}
.about_us {
  max-width: 100%;
  text-align: justify;
  padding: 3em;
}
.vision {
  min-width: 50%;
  p {
    text-align: justify;
    color: rgb(30, 67, 116);
  }
}
.mission_vision {
  max-width: 50%;
  display: flex;
  gap: 3em;
}
.about_nav{
  padding-right: 2em;
  min-width: 100%;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  line-height: 4em;
  li{
    list-style-type: none;
    color: #979fa5;
    font-weight: 600;
  }
  :hover li{
    color: #0f4f7c;
    cursor: pointer;
  }
}
.mission {
  text-align: justify;
  min-width: 70%;
  border-right: solid rgb(30, 67, 116);
  padding-right: 3em;
  p {
    color: rgb(30, 67, 116);
  }
}
.mission_title {
  color: rgb(152, 233, 186);
  font-weight: 600;
}
.about {
  min-width: 100%;
}
.about_content_sub_container{
  display: flex;
  gap: 3em;
}
.about_image_container{
  max-width: 20vw;
}
.about_title {
  font-size: 1.3em;
  color: #1e6aa0;
  font-weight: 600;
  line-height: 1.8em;
}
.about_description {
  font-size: 1em;
  color: #062d49;
  line-height: 1.4em;
  min-width: 100%;

}
.sectors {
  line-height: zem;
  span {
    color: #1e6aa0;
  }
}
li {
  list-style-type: "👉";
  padding-inline-start: 1ch;
  color: #1e6aa0;
}
.sectors{
  color: #c2c9ce;
  font-weight: 600;
}
.objectives_sect {
  span {
    color: #052c0b;
    font-weight: 600;

  }
  li {
    font-size: 1em;
    color: #0a151c;
    list-style-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23808"><g transform="scale(0.5) translate(0, 15)" transform-origin="center"><path d="M18 4c-4-1-6 3-6 3s-2-4-6-3-4 6-2 8l8 8 8-8c2-2 2-7-2-8z"></path></g></svg>');
  }
  li::marker {
    font-size: 3em;
  }
}
@media screen and (max-width:900px) {
  .intro_title{
text-align: center;
font-size: 0.9em;
max-width: 100%;
  }
  
}
@media screen and (max-width:800px) {
  .about_us_container{
    gap: 0.4em;
  }
  .about_us {
    min-width: 100%;
  }
  .mission_vision{
    flex-direction: column;
    min-width: 100%;
    gap: 1em;
    
  }
  .mission{
    border: none;
    min-width: 100%;
  }
}
@media screen and (max-width: 600px) {
  .about_content_sub_container {
    flex-direction: column;
    gap: 1em;
    max-width: 90%;
   
  }
  .about{
max-width: 100%;
margin-left: 0 auto;
  }
  .about_us_container{
    flex-direction: column;
    display: flex;
    margin-top: 5em;
    max-width: 100%;
  }
  .about_image_container{
    max-width: 90%;
  }
  .about_nav{
    max-width: 100%;
  }
  .about_us{
    max-width: 100%;
  }
  .about_description {
    font-size: 0.9em;
    padding: 0.5em;
    max-width: 100%;
  }
  .about_nav ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}
