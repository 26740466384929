.App {
    text-align: center;
    background-color: white; 
    background-repeat: repeat-x, repeat;
    min-height: 100vh;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: Arial, sans-serif;
    min-width: 100%;
  }
  .header{
    
    align-items: center;
    justify-content: center;
    align-items: center;
    min-width: 100%
  }