.navHeader {
  display: flex;
  gap: 2em;
  justify-content: flex-end;
  align-items: center;
  text-align: center;
  padding: 2em;
  min-width: 50%;
  margin: 0 auto;
}

.container_main {
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  background-color: white;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 1em 0;
}

.tab_details {
  padding: 1em;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 0.5em;
  text-decoration: none;
  min-width: 8em;
  text-align: center;
  color: black;
}

.tab_details:hover {
  background-color: #135e96;
  color: white;
}

.tab_details_donate {
  text-decoration: none;
  color: white;
  background-color: #0a4b78;
  padding: 1em;
  min-width: 8em;
  text-align: center;
}

.updated_logo {
  height: 12vh;
  width: 90%;
}

.dropdown_icon_container {
  display: none;
}

.dropdown_icon {
  font-size: 2em;
  cursor: pointer;
}

.mobile_nav {
  display: flex;
  flex-direction: column;
  gap: 1em;
  padding: 1em;
  background-color: white;
  position: absolute;
  top: 70px;
  width: 100%;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

@media screen and (min-width: 1200px) {
  .dropdown_icon_container {
    display: none;
  }
}

@media screen and (max-width: 1300px) {
  .navHeader {
    margin: 0;
    justify-content: center;
  }



  .tab_details {
    min-width: 5em;
  }
}

@media screen and (max-width: 1100px) {
  .navHeader {
    gap: 3em;
  }

  .dropdown_icon_container {
    display: none;
  }

  .updated_logo {
    display: none;
  }
  .tab_details{
    font-size: 0.9em;
  }
}
 
@media screen and (max-width: 800px) {
  .container_main {
    max-width: 100%;
  }
 .updated_logo {
  display: inline;
}
  .dropdown_icon_container {
    display: inline;
  }

  .dropdown_icon {
    font-size: 3em;
  }
  .mobile_nav {
    background-color: whitesmoke;
    max-width: 60%;
    z-index: 999;
    box-shadow: -5px 5px 10px 0px rgba(0, 0, 0, 0.5);
    position: fixed;
    right: 0;
    margin-top: 1.4em;
    margin-left: auto;
    margin-right: 20px; /* Adjust as needed */
  }
  .navHeader {
    display: none;
  }
}
@media screen and (max-width:500px){
  .updated_logo {
    width:70%;
    height: 70%;
  }
  .container_main{
    max-width: 100%;
  }
  .co_logo{
    max-width: 50%;
  }
   
}